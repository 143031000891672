<template>
    <div class="w-full max-w-[1340px] m-auto p-4">
        <div
            v-for="(course, index) in courses"
            :key="index" 
            class="mb-10 pt-10 border-t"
        >
             <h2 class="flex items-center uppercase font-title tracking-[3.5px] mb-4">
                {{ course.name }}
                <a
                    v-if="course.is_locked"
                    :href="`/purchase/course/${course.slug}`"
                    class="
                        flex basis-[285px] justify-center justify-items-center
                        text-center p-5 bg-blue-500 text-white text-base ml-2"
                >
                    Unlock Course
                    <img class="ml-2" src="/svg/lock.svg" alt="lock icon">
                </a>
             </h2>
            
             <div class="mb-5 text-[18px]" v-html="course.description"></div>
             <div class="grid gap-6 md:grid-cols-3 md:gap-6 lg:grid-cols-3 lg:gap-6">
                 <div
                    v-for="(video, index) in course.videos"
                    :key="index"
                    class="block rounded-lg shadow-lg hover:shadow"
                >
                    <div v-if="!course.is_locked">
                        <a
                            :class="video?.is_locked ? 'cursor-not-allowed' : ''"
                            :href="video?.is_locked ? null : `/courses/${course.slug}/videos/${video.slug}`"
                        >
                            <div class="relative img-container">
                                <div :class="video?.is_locked ? 'aspect-w-4 aspect-h-3 opacity-75' : 'aspect-w-4 aspect-h-3'">
                                    <img v-if="video.video_thumbnail" class="object-cover rounded-t-lg" :src="video.video_thumbnail" :alt="video.name">
                                    <img v-else class="object-cover rounded-t-lg" src="img/logo-tile.png" alt="image placeholder">
                                </div>
                                <a
                                    v-if="video?.is_locked" :href="video?.is_locked ? `/purchase/video/${video.slug}` : null"
                                    class="absolute bottom-0 right-0 flex justify-between justify-items-center p-5 bg-blue-500 text-white"
                                >
                                    Unlock Video <img class="ml-2" src="/svg/lock.svg" alt="lock icon">
                                </a>
                            </div>
                            <div :class="video?.is_locked ? 'p-4 opacity-75' : 'p-4'">
                                <h5 class="mb-2 text-2xl font-bold tracking-tight">
                                    {{ video.name }}
                                </h5>
                                <p class="font-normal line-clamp-3" v-html="video.description"></p>
                            </div>
                        </a>
                    </div>
                    <a
                        v-else
                        :class="video?.is_intro_video ? '' : 'cursor-not-allowed'"
                        :href="video?.is_intro_video ? `/courses/${course.slug}/videos/${video.slug}` : null"
                    >
                        <div :class="video?.is_intro_video ? 'aspect-w-4 aspect-h-3' : 'aspect-w-4 aspect-h-3 opacity-75'">
                            <img v-if="video.video_thumbnail" class="object-cover rounded-t-lg" :src="video.video_thumbnail" :alt="video.name">
                            <img v-else class="object-cover rounded-t-lg" src="img/logo-tile.png" alt="image placeholder">
                        </div>
                        <div :class="video?.is_intro_video ? 'p-4' : 'p-4 opacity-75'">
                            <h5 class="mb-2 text-2xl font-bold tracking-tight">
                                {{ video.name }}
                            </h5>
                            <p class="font-normal line-clamp-3" v-html="video.description"></p>
                        </div>
                    </a>
                 </div>
             </div>
         </div>
    </div>
</template>

<script>
export default {
    name: "CourseList",
    props: {
        courses: {
            default: () => [],
            type: Array,
        }
    }
}
</script>
