<template>
    <Bar
        :id="`my-chart-id${_uid}`"
        :options="chartOptions"
        :data="chartData"
        class="bar-chart"
    />
</template>


<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)



export default {
  name: 'BarChart',
  components: { Bar },
  data() {
    return {
      chartOptions: {
        responsive: true,
        scales: {
          x: {
            grid: {
              display: false
            }
          },
        },
        elements: {
          bar: {
            borderRadius: {
              topRight: 8,
              topLeft: 8
            }
          }
        },
        plugins: {
          legend: {
            display: false,
          }
        }
      }
    }
  },
    props: {
        title: {
            type: String,
            required: false,
        },
        values: {
          required: true
        },
        labels: {
            type: Array,
            required: true
        }
    },
    computed: {
      chartData() {
        return {
          labels: this.labels,
          datasets: [
            {
              data: this.values,
              backgroundColor: ["#fc5b2b", "#6c6868"]
            }
          ]
        }
      }
    }
}
</script>

<style scoped>
/* resize responsive */
  .bar-chart {
    height: fit-content !important;
    width: 100% !important;
  }
</style>