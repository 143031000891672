<template>
    <div class="w-full max-w-[420px]" v-if="article.id !== featured_article_id">
        <a
            :href="article.url"
            class="relative bg-brown-100 flex flex-col justify-between items-start overflow-hidden rounded-[20px] shadow-3xl h-full"
        >
            <img
                class="
                    w-full
                    h-full
                    md:max-w-[460px]
                    max-h-[300px]
                    object-cover
                    aspect-square
                "
                :src="article?.thumbnail?.urls?.default"
                :alt="article.title"
            >
            <div class="flex flex-col w-full h-full justify-between">
                <div class="grow min-h-fit border-b border-[#cfbebe] p-5 w-full">
                    <h2 class="font-title text-2xl md:text-[28px] text-left font-bold ">
                        {{ article.title }}
                    </h2>
                </div>

                <div class="p-5 uppercase font-title tracking-[2.5px] flex justify-between">
                    <a
                        v-if="article?.categories?.length > 0"
                        :href="`/articles?category=${article.categories[0].slug}`"
                        class="bg-[#e3dddd] rounded-full py-1 px-2"
                    >
                        {{ article.categories[0].name }}
                    </a>
                </div>

                <a
                    class="bg-black text-white w-full p-5 uppercase font-title tracking-[2.5px]"
                    :href="article.url"
                >
                    Read Article
                </a>
            </div>
        </a>
    </div>
</template>

<script>
export default {
    props: {
        featured_article_id: {
            type: Number,
            required: false
        },
        article: {
            type: Object,
            required: true
        }
    }
}
</script>
