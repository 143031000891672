<template>
    <div v-if="clubs.length" class="my-10">
        <div class="w-full flex flex-row justify-center items-center mb-5 hidden md:flex">
            <div class="md:w-4/12 label">Location</div>
            <div class="md:w-2/12 label">Report Type</div>
            <div class="md:w-2/12 label">Report Name</div>
        </div>
        <div class="w-full flex flex-col md:flex-row justify-center items-center md:bg-brown-100 border-b border-brown-200">
            <div class="select-arrows w-full md:w-4/12 text-4xl">
                <div class="md:w-4/12 my-4 px-4 label visible md:hidden">Location</div>
                <select
                    v-model="activeClub"
                    name="timetableLocation"
                    id=""
                    class="
                        font-title
                        text-2xl
                        front-bold
                        border-l
                        border-brown-200
                        border-r
                        bg-brown-100
                    "
                >
                    <option :value=null>Select Location</option>
                    <option
                        v-for="club in clubs"
                        :key="club.id"
                        :value="club.id"
                    >
                        {{ club.name }}
                    </option>
                </select>
            </div>
            <div class="select-arrows w-full md:w-2/12 text-4xl">
                <div class="md:w-2/12 my-4 px-4 label visible md:hidden">Report Type</div>
                <select
                    v-model="activeCategory"
                    name="timetableLocation"
                    id=""
                    class="
                        font-title
                        text-2xl
                        front-bold
                        border-l
                        border-brown-200
                        border-r
                        bg-brown-100
                    "
                >
                    <option :value=null>Select Category</option>
                    <option
                        v-for="report in reportGroupByCategory"
                        :key="report[0].category"
                        :value="report[0].category"
                    >
                        {{ report[0].category }}
                    </option>
                </select>
            </div>
            <div class="select-arrows w-full md:w-2/12 text-4xl">
                <div class="md:w-2/12 my-4 px-4 label visible md:hidden">Report Name</div>
                <select
                    v-model="activeDate"
                    name="timetableLocation"
                    id=""
                    class="
                        font-title
                        text-2xl
                        front-bold
                        border-l
                        border-brown-200
                        border-r
                        bg-brown-100
                    "
                >
                    <option :value=null>Select Date</option>
                    <option
                        v-for="report in reportBySelectedCategory"
                        :key="report.id"
                        :value="report.id"
                    >
                        {{ report.display_name }}
                    </option>
                </select>
            </div>
        </div>
        <div
            v-if="activeClub && activeCategory && activeDate"
            class="w-full max-w-[1340px] m-auto px-4"
        >
            <LocationIndex
                :active-report="activeGraphReport"
                :is-rating-report="isRatingReport"
                :location="selectedLocation[0]"
                :reports="activeGraphReport"
                :is-club-profile-viewable="isClubProfileViewable"
            />
            <ReportsIndex
                :active-report="activeGraphReport"
                :location="selectedLocation[0]"
                :chart-data="chartValues"
            />
            <RankingsIndex
                v-if="this.reportBySelectedCategory[0].category !== 'Independent'"
                :active-club="selectedLocation[0]"
            />
        </div>
    </div>
    <div v-else class="mt-10 flex flex-col gap-10 justify-center items-center">
        <p>You currently have no reports</p>
        <a class="button" href="/contact-us">
            <span>Request</span>
        </a>
    </div>
</template>

<script>
import ReportsIndex from './ReportsIndex.vue';
import RankingsIndex from './RankingsIndex.vue';
import LocationIndex from './LocationIndex.vue';

export default {
    components: {
        ReportsIndex,
        RankingsIndex,
        LocationIndex
    },
    props: {
        clubs: {
            type: Array,
            required: false,
            default: () => []
        },
        reports: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data() {
        return {
            activeClub: null,
            activeDate: null,
            activeCategory: null,
            activeReport: null,
            chartValues: []
        }
    },
    mounted() {
        const report = this.reports[0];

        this.activeClub = report.club_id;
    },
    computed: {
        reportGroupByCategory() {
            const filteredReport = this.reports.filter(report => report.club_id === this.activeClub);
            const groupByCategory = filteredReport.reduce((group, report) => {
            const { category } = report;
                group[category] = group[category] ?? [];
                group[category].push(report);
                return group;
            }, {});

            return groupByCategory;
        },
        reportBySelectedCategory() {
            return this.reports.filter(report => report.category === this.activeCategory && report.club_id === this.activeClub);
        },
        selectedLocation() {
            return this.clubs.filter(club => club.id === this.activeClub);
        },
        activeGraphReport() {
            return this.reports.find(report => report.id === this.activeDate);
        },
        isRatingReport() {
            const report = Object.keys(this.reportGroupByCategory);
            return report.some(value => value === 'Rating');
        },
        isClubProfileViewable() {
            const selectedClub = this.clubs.filter(club => club.id === this.activeClub);
            return selectedClub[0].city.is_club_profile_viewable;
        }
    },
    watch: {
        activeClub() {
            const activeClubFilterdReport = this.reports.filter(report => report.club_id === this.activeClub);
            this.activeCategory = activeClubFilterdReport.length >= 1 ? activeClubFilterdReport[0].category : null;
            this.activeDate = activeClubFilterdReport.length >= 1 ? activeClubFilterdReport[0].id : null;
        },
        activeCategory() {
            if (this.activeCategory) {
                this.activeReport = this.reports.find(report => report.category === this.activeCategory);
                this.activeDate = this.reportBySelectedCategory.length >= 1 ? this.reportBySelectedCategory[0].id : null;
                let data = []
                for (const keyCategory in this.activeReport?.content.scores2.general)  {
                    const scores = this.activeReport?.content.scores2.general[keyCategory].percent;
                    if (scores) {
                        data.push(scores)
                    }
                }
                this.chartValues = data;
            } else {
                this.activeDate = null;
            }
        },
        activeDate() {
            if (this.activeDate) {
                this.activeReport = this.reports.find(report => report.id === this.activeDate);
                let data = []
                for (const keyCategory in this.activeReport?.content?.scores2?.general)  {
                    const scores = this.activeReport?.content.scores2.general[keyCategory].percent;
                    if (scores) {
                        data.push(scores)
                    }
                }
                this.chartValues = data;
            }
        },
    }
}
</script>
