<template>
    <a :href="club.url">
        <div class="bg-brown-100 mx-auto flex flex-col justify-between items-start overflow-hidden rounded-[20px] shadow-3xl w-full max-w-[420px] h-full">
            <div class="relative w-full">
                <a
                    v-if="club?.city?.shorthand"
                    :href="club.city.url"
                    class="absolute top-0 left-0 text-white font-title text-[28px] font-bold py-1 px-5 m-5 bg-black rounded-lg"
                >
                    {{ club.city.shorthand }}
                </a>
                <div class="h-[260px]">
                    <img
                    class="
                        w-full
                        h-full
                        object-cover
                        aspect-square
                    "
                    :src="club.thumbnail ? club.thumbnail : '/img/logo-tile.png'"
                    :alt="club.brand.name"
                >
                </div>
                <div
                    v-if="club.category"
                    class="
                        absolute
                        bottom-0
                        right-0
                        flex
                        justify-center
                        items-center
                        h-[46px]
                        bg-[#e3dddd]
                        px-5
                        py-1
                        rounded-tl-lg
                        font-title
                    "
                >
                    {{  club.category.name }}
                </div>
            </div>
            <div class="flex flex-col w-full h-full justify-between">
                <div class="min-h-fit border-b border-[#cfbebe] p-5 w-full grow flex items-center">
                    <h2 class="font-title text-2xl md:text-[28px] text-left font-bold ">
                        {{ club.name }}
                    </h2>
                </div>

                <div class="p-5 uppercase font-title tracking-[2.5px] truncate">
                    {{ club?.city?.name }}{{ club?.city?.country ? `, ${club.city.country}` : '' }}
                </div>

                <div class="bg-[#e3dddd] px-5 flex items-center h-[60px]">
                    <div class="max-w-[55%]">
                        <img
                            class="h-[40px]"
                            v-if="club?.report?.overall"
                            :src="`/svg/${club.report.overall}.svg`"
                            :alt="club.report.overall"
                        >
                    </div>
                </div>
                <div class="bg-black text-white w-full p-5 uppercase font-title tracking-[2.5px]">View Club</div>
            </div>
        </div>
    </a>
</template>

<script>
export default {
    props: {
        club: {
            type: Object,
        }
    },
    data() {
        return {
            rating: Math.floor(Math.random() * (5 - 3 + 1)) + 3, // AI wrote this
        }
    }
}
</script>
