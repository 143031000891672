<template>
    <div class="w-full flex justify-center flex-col items-center max-w-[1420px] m-auto px-5 my-10">

        <div class="w-full">
            <studio-skeleton v-if="this.loading" />
            <template v-else-if="clubs.length > 0">
                <div
                    v-if="clubs.length && view === 'grid'"
                    class="grid grid-cols-1 md:grid-cols-3 gap-5"
                >
                    <studio-card
                        v-for="club in clubs"
                        :key="club.id"
                        :club="club"
                    ></studio-card>
                </div>
                <div
                    v-if="clubs.length && view === 'list'"
                    class="grid grid-cols-1 gap-5"
                >
                    <studio-list
                        v-for="club in clubs"
                        :key="club.slug"
                        :club="club"
                    ></studio-list>
                </div>
            </template>
            <template v-else>
                <div class="max-w-[960px] m-auto">
                    <h2 class="font-title font-bold mb-5 uppercase">
                        No matches found
                    </h2>
                    <p>Please try another search.</p>
                </div>
            </template>
        </div>

        <a
            v-if="load_type === 'viewall'"
            :href="viewAllUrl"
            class="button max-w-fit mt-10"
        >
            <span>
                View Clubs
            </span>
        </a>
        <button
            v-else-if="load_type === 'loadmore' && !last_page && !initial_load"
            :disabled="loading"
            type="button disabled:opacity-75"
            @click="getClubs"
            class="button max-w-fit mt-10"
        >
            <span>
                Load More
            </span>
        </button>
    </div>
</template>

<script>
import StudioCard from './StudioCard';
import StudioList from './StudioList';
import StudioSkeleton from './StudioSkeleton.vue';
export default {
    components: {
        StudioCard,
        StudioList,
        StudioSkeleton,
    },
    props: {
        city: {
            type: Object,
            required: false,
            default: null
        },
        concept: {
            type: String,
            required: false,
        },
        exclude: {
            type: Array,
            required: false,
            default: () => []
        },
        load_type: {
            type: String,
            defaultult: 'loadmore'
        },
        limit: {
            type: Number,
            required: false
        },
        order: {
            type: String
        },
        ratings: {
            type: Array,
        },
        view: {
            type: String
        },
        keyword: {
            type: String
        },
    },
    data() {
        return {
            initial_load: true,
            last_page: false,
            loading: false,
            page: 0,
            clubs: [],
            viewAllUrl: this.city ? `/cities/${this.city.slug}/clubs` : "/cities"
        }
    },
    mounted () {
        this.getClubs();
    },
    computed: {
      requestParams() {
        const params = {
          exclude: this.exclude,
          page: this.page + 1,
          order: this.order
        }

        if (this.limit) {
          params.per_page = this.limit;
        }

        if (this.city) {
          params.city = this.city.slug;
        }

        if (this.concept) {
          params.category = this.concept;
        }

        if (this.keyword) {
          params.keyword = this.keyword;
        }

        // If the ratings array exists, and 'all' is NOT found in the array
        // append the ratings to the ranking query param.
        if (this.ratings.length > 0 && this.ratings.indexOf('all') < 0) {
          params.ranking = this.ratings.join();
        }

        return params;
      },
    },
    methods: {
        async getClubs() {
            if (this.loading) {
              return;
            }

            this.loading = true;
            this.error = null;

            const params = {
                exclude: this.exclude.join(','),
                page: this.page + 1,
                order: this.order
            }

            if (this.limit) {
                params.per_page = this.limit;
            }

            if (this.city) {
                params.city = this.city.slug;
            }

            if (this.concept) {
                params.category = this.concept;
            }

            if (this.keyword) {
                params.keyword = this.keyword;
            }

            // If the ratings array exists, and 'all' is NOT found in the array
            // append the ratings to the ranking query param.
            if (this.ratings.length > 0 && this.ratings.indexOf('all') < 0) {
                params.ranking = this.ratings.join();
            }

            fetch('/api/clubs?' + new URLSearchParams(Object.entries(params)), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => response.json())
            .then((response) => {
                let group_five = [];
                let group_four = [];
                let group_recommended = [];
                // group first to ensure that shuffle result does not affect the sort by score
                response.data.forEach((club) => {
                    switch (club?.report?.overall) {
                        case 'five':
                            group_five.push(club);
                            break;
                        case 'four':
                            group_four.push(club);
                            break;

                        // default for "recommended"
                        default:
                            group_recommended.push(club);
                            break;
                    }
                });

                // shuffle result
                group_five = group_five.map(value => ({ value, sort: Math.random() })).sort((a, b) => a.sort - b.sort).map(({ value }) => value)
                group_four = group_four.map(value => ({ value, sort: Math.random() })).sort((a, b) => a.sort - b.sort).map(({ value }) => value)
                group_recommended = group_recommended.map(value => ({ value, sort: Math.random() })).sort((a, b) => a.sort - b.sort).map(({ value }) => value)

                this.clubs.push(...group_five, ...group_four, ...group_recommended);
                this.page++;
                this.last_page = response.meta.last_page === this.page;
            })
            .catch((error) => {
                this.error = 'Error getting posts.';
            })
            .finally(() => {
                this.initial_load = false;
                this.loading = false;
            });
        },

        async reset() {
          return new Promise((resolve) => {
            this.initial_load = true;
            this.last_page = false;
            this.page = 0;
            this.clubs = [];
            resolve();
          }).then(() => {
            this.getClubs();
          });
        }
    },
    watch: {
        order () {
            this.reset();
        },
        ratings () {
            this.reset();
        },
        concept () {
            this.reset();
        },
        keyword () {
            this.reset();
        }
    }
}
</script>
