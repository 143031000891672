<template>
    <a
        :href="article.url"
        class="
            relative
            bg-brown-100
            flex
            flex-col
            justify-between
            items-stretch
            md:max-h-[586px]
            md:flex-row
            overflow-hidden
            rounded-[20px]
            shadow-3xl
            h-full
        "
    >
        <div class="w-full">
            <img
                class="
                    w-full
                    h-full
                    object-cover
                    aspect-square
                "
                :src="article.thumbnail.urls.default"
                :alt="article.title"
            >
        </div>
        <div class="flex flex-col w-full min-w-[460px] md:min-h-[460px] h-full justify-between">
            <div class="h-28 min-h-fit p-10 w-full">
                <h2 class="font-title text-2xl md:text-[36px] leading-[1.17] text-left font-bold">
                    {{ article.title }}
                </h2>
            </div>

            <div class="w-full flex flex-col border-t border-[#cfbebe] ">
                <div class="grow p-5 uppercase font-title tracking-[2.5px] flex justify-between">
                    <a
                        v-if="article.categories.length > 0"
                        :href="`/articles?category=${article.categories[0].slug}`"
                        class="bg-[#e3dddd] rounded-full py-1 px-2"
                    >
                        {{ article.categories[0].name }}
                    </a>
                </div>

                <a
                    class="bg-black text-white w-full p-5 uppercase font-title tracking-[2.5px]"
                    :href="article.url"
                >
                    Read Article
                </a>
            </div>
        </div>
    </a>
</template>

<script>
export default {
    props: {
        article: {
            type: Object,
            required: true
        }
    }
}
</script>