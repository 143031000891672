<template>
    <div class="relative bg-brown-100 flex flex-col justify-between items-start overflow-hidden rounded-[1.25rem] shadow-3xl">
        <div class="flex flex-col w-full h-full justify-between">
            <div class="flex justify-between border-b border-[#cfbebe] w-full p-4 items-center">
                <div class="w-full flex flex-wrap md:flex-nowrap items-center gap-1 md:gap-4">
                    <h2 class="font-title font-bold text-lg md:text-xl lg:text-3xl tracking-tight basis-full shrink-0 md:basis-auto md:shrink md:grow-0">
                        {{ club.name }}
                    </h2>

                    <div class="font-title uppercase sm:whitespace-nowrap tracking-[2.5px] text-xs md:text-sm lg:text-base flex-1 md:basis-auto md:grow-0">
                        {{ club?.city?.name }}{{ club?.city?.country ? `, ${club.city.country}` : '' }}
                    </div>

                    <div
                        v-if="club.category"
                        class="
                            font-title
                            text-sm lg:text-base
                            ml-auto
                            basis-0 shrink-0 grow-0
                        "
                    >
                        {{  club.category.name }}
                    </div>
                </div>
            </div>

            <div class="flex">
                <div class="bg-[#e3dddd] grow p-5 w-full flex items-center">
                    <img
                        class="h-5"
                        v-if="club?.report?.overall"
                        :src="`/svg/${club.report.overall}.svg`"
                        :alt="club.report.overall"
                    >
                </div>
                <a
                    class="bg-black button text-white w-full px-12 m-0 mx-[-.5rem] px-[1rem]"
                    :href="club.url"
                >
                    <span>View <div class="hidden sm:inline">Location</div></span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        club: {
            type: Object,
        }
    },
    data() {
        return {
            rating: Math.floor(Math.random() * (5 - 3 + 1)) + 3, // AI wrote this
        }
    }
}
</script>
