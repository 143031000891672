<template>
    <div class="fixed m-4 bottom-0 right-0">
      <div
        v-show="show"
        class="
          flex
          items-center
          p-4
          mb-4
          w-full
          max-w-xs
          text-gray-500
          bg-white
          rounded-lg
          shadow
          dark:text-gray-400 dark:bg-gray-800
          transition-all
        "
        role="alert"
      >
        <div
          v-if="type === 'success'"
          class="
            inline-flex
            flex-shrink-0
            justify-center
            items-center
            w-8
            h-8
            text-green-500
            bg-green-100
            rounded-lg
            dark:bg-green-800 dark:text-green-200
          "
        >
          <svg
            aria-hidden="true"
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <span class="sr-only">Check icon</span>
        </div>
        <div
          v-if="type === 'error'"
          class="
            inline-flex
            flex-shrink-0
            justify-center
            items-center
            w-8
            h-8
            text-red-500
            bg-red-100
            rounded-lg
            dark:bg-red-800 dark:text-red-200
          "
        >
          <svg
            aria-hidden="true"
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <span class="sr-only">Error icon</span>
        </div>
        <div
          v-if="type === 'warning'"
          class="
            inline-flex
            flex-shrink-0
            justify-center
            items-center
            w-8
            h-8
            text-orange-500
            bg-orange-100
            rounded-lg
            dark:bg-orange-700 dark:text-orange-200
          "
        >
          <svg
            aria-hidden="true"
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <span class="sr-only">Warning icon</span>
        </div>
  
        <div class="ml-3 text-sm font-normal">{{ notification }}</div>
  
        <button
          type="button"
          class="
            ml-auto
            -mx-1.5
            -my-1.5
            bg-white
            text-gray-400
            hover:text-gray-900
            rounded-lg
            focus:ring-2 focus:ring-gray-300
            p-1.5
            hover:bg-gray-100
            inline-flex
            h-8
            w-8
            dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700
          "
          data-dismiss-target="#toast-success"
          aria-label="Close"
          @click="destroyNotification"
        >
          <span class="sr-only">Close</span>
          <svg
            aria-hidden="true"
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      message: {
        required: true,
      },
      type: {
        required: true,
      },
    },
    data() {
      return {
        show: false,
        notification: this.message,
        alertClass: this.type,
        hideTimeout: false,
      };
    },
    created() {
      if (this.notification) {
        this.showNotification();
      }
    },
  
    methods: {
      showNotification() {
        this.show = true;
        this.hideNotification();
      },
  
      hideNotification() {
        this.hideTimeout = setTimeout(() => {
          this.show = false;
        }, 5000);
      },
  
      destroyNotification() {
        this.show = false;
        clearTimeout(this.hideTimeout);
      },
    },
  };
  </script>