<template>
    <section class="flex flex-col items-center justify-center">
        <div class="box">
            <div class="progress" :ref="`radial${_uid}`">
                <div class="inner text-white text-2xl">
                    {{ percentage === null ? 'N/A' : Math.floor(percentage) }}
                </div>
            </div>
        </div>
        <div v-if="title" class="text-center font-bold text-sm mt-[15px]">{{ title }}</div>
    </section>
</template>

<script>
import * as ProgressBar from "../../packages/progressbar.min.js";

export default {
    props: {
        title: {
            type: String,
            required: false,
        },
        percentage: {
            type: [Number],
        }
    },
    data() {
        return {
            progressBar: null
        }
    },
    mounted () {
        this.load();
    },
    watch: {
        percentage(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.progressBar.animate(this.percentage / 100); // percent
            }
        }
    },
    methods: {
        load () {
            this.progressBar = 
                new ProgressBar.Circle(this.$refs[`radial${this._uid}`], {
                color: '#fc5b2b',
                strokeWidth: 10,
                duration: this._uid * 100,//2000, // milliseconds
                easing: 'easeInOut',
                trailColor: '#ccc',
                trailWidth: 10,
                duration: 1200,
            });

            this.progressBar.animate(this.percentage / 100); // percent
        }
    }
}
</script>

<style scoped>
.box {
  display: inline-block;
}

.progress {
  width: 100%;
  height: 100%;
  max-width: 80px;
  max-height: 80px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1 / 1;
}

.progress > .inner {
  position: absolute; 
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  width: 100%;
  height: 100%;
  max-width: 65px;
  max-height: 65px;
  aspect-ratio: 1 / 1;
}

.progress > svg {
  height: 100%;
  display: block;
}
</style>