<template>
  <div class="hero-banner">
    <vueper-slides
      lazy
      autoplay
      :arrows="false"
      :infinite="true"
      :fixed-height="true"
      class="no-shadow h-[480px] sm:h-[600px] md:h-[768px]"
    >
      <vueper-slide
        v-for="(slide, i) in slides"
        :key="i"
        :title="slide.title"
        :image="slide.image"
      />
    </vueper-slides>
    <div class="absolute top-[calc(50%-90px)] w-full m-auto flex flex-col justify-center items-center">
        <div class="z-10 w-full sm:w-9/12 md:w-full xl:w-8/12 px-5 flex flex-col justify-center items-center text-center">
            <h1 class="text-white font-bold tracking-tight leading-tight text-[22px] md:text-[28px] lg:text-[38px] mb-0 sm:mb-4">
                {{ title }}
            </h1>
            <div v-html="description" class="text-[18px] sm:text-[24px] text-white"></div>
            <div
              class="-skew-x-12
              flex justify-center items-center
              text-sm text-font-light text-center
              bg-orange-500 hover:bg-orange-600
              tracking-extrawide
              mt-4 sm:mt-8"
            >
              <a 
                :href="button[0].link"
                class="bg-transparent hover:bg-transparent block skew-x-12 button m-0"
              >
                  {{ button[0].label }}
              </a>
            </div>
        </div>
    </div>
  </div>
  </template>

  <script>
    import { VueperSlides, VueperSlide } from 'vueperslides'
    import 'vueperslides/dist/vueperslides.css'

  export default {
    components: {
      VueperSlides,
      VueperSlide
    },
    props: {
      slides: {
        type: Array
      },
      darken: {
        type: Boolean,
        required: false,
        default: false
      },
      title: {
        type: String,
        default: ''
      },
      description: {
        type: String,
        default: ''
      },
      button: {
        type: Array,
        default: ''
      }
    }
  }
  </script>

  <style>
    .vueperslides__bullets {
        position: absolute;
        bottom: 36px;
    }

    .vueperslide__content-wrapper {
        position: absolute;
        top: 0;
        width: 100%;
        background-color: rgba(0, 0, 0, .5);
        color: white;

    }
    .vueperslide__title {
        position: fixed;
        bottom: 16px;
        width: max-content;
    }

    .vueperslide {
      background-position: top;
    }

    .vueperslides__bullet .default {
        background-color: white;
    }

    .vueperslides__bullet--active .default {
        background-color: #fc5b2b;
        border-color: #fc5b2b;
    }
  </style>
