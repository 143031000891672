<template>
    <div class="w-full">
        <div class="flex flex-col justify-center items-center w-full">
            <h3 class="text-center uppercase mb-[20px] tracking-[3.5px]">{{ title }}</h3>
            <city-filters
                v-if="show_city_select"
                :selected_city="city"
                :concept.sync="concept"
                :ratings.sync="ratings"
                :order.sync="order"
                :view.sync="view"
                :keyword.sync="keyword"
                :show_rating_filters="show_rating_filters"
            ></city-filters>
        </div>

        <div class="w-full">
            <studios-index
                v-if="show_clubs"
                :city="city"
                :concept="concept"
                :exclude="exclude"
                :ratings="ratings"
                :order="order"
                :view="view"
                :keyword="keyword"
                :limit="limit"
                :load_type="load_type"
            ></studios-index>
            <article-index
                v-else
                :city="city.slug"
                :featured=false
                :load_type='"loadmore"'
                :limit="limit"
            >
            </article-index>
        </div>
    </div>
</template>

<script>
import CityFilters from './CityFilters.vue';
import StudiosIndex from '../studios/StudiosIndex.vue';
import ArticleIndex from '../articles/ArticlesIndex.vue';
export default {
    components: {
        CityFilters,
        StudiosIndex,
        ArticleIndex,
    },
    props: {
        city: {
            type: Object,
            required: false,
            default: null,
        },
        exclude: {
          type: Array,
          required: false,
          default: () => []
        },
        initialConcept: {
          type: String,
          required: false,
          default: null
        },
        limit: {
          type: Number,
          required: false
        },
        load_type: {
          type: String,
          default: 'none'
        },
        show_city_select: {
          type: Boolean,
          required: false,
          default: true
        },
        show_rating_filters: {
          type: Boolean,
          required: false,
          default: true,
        },
        show_clubs: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: 'Location',
            required: false,
        },
    },
    data() {
        return {
            cities: [],
            clubs: [],
            ratings: [],
            concept: null,
            order: "desc",
            view: "grid",
            keyword: null
        }
    },
    created() {
        this.concept = this.initialConcept;
    }
}
</script>
