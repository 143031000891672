<template>
    <div>
        <radial-progress
            v-if="chartType=='radial-progress'"
            :title="title"
            :percentage="values"
        ></radial-progress>
        <bar
            v-if="chartType=='bar'"
            :title="title"
            :labels="labels"
            :values="values"
        ></bar>
    </div>
</template>

<script>
import RadialProgress from './chart-types/RadialProgress.vue';
import Bar from './chart-types/Bar.vue'

export default {
    components: {
        RadialProgress,
        Bar
    },
    props: {
        chartType: {
            type: String
        },
        title: {
            type: String,
            required: false,
        },
        values: {
            required: true
        },
        labels: {
            type: Array,
            required: false,
        }
    }

}
</script>