<template>
    <div class="w-full max-w-[1340px] m-auto p-4">
        <div
            v-if="video.prerequisite_passed"
            class="aspect-w-16 aspect-h-7"
        >
            <video
                id="my-player"
                class="video-js"
                controls
                preload="auto"
                :poster="video.video_thumbnail"
                data-setup='{}'
                controlsList="nodownload"
                oncontextmenu="return false;"
                @ended="onEnd()"
            >
                <source :src="video.url" type="video/mp4"/>
                <p class="vjs-no-js">
                    To view this video please enable JavaScript, and consider upgrading to a
                    web browser that
                    <a href="https://videojs.com/html5-video-support/" target="_blank">
                    supports HTML5 video
                    </a>
                </p>
            </video>
        </div>
        <!-- cannot view need to pass a test on the -->
        <div
            v-else
            class="relative overflow-hidden rounded-lg bg-cover bg-no-repeat pb-[56.25%] text-center"
            :style="{ 'background-image': `url(${video.video_thumbnail})` }"
        >
            <div
                class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed"
                style="background-color: rgba(0, 0, 0, 0.6)">
                <div class="flex h-full items-center justify-center p-4">
                    <div class="flex flex-col justify-center text-white">
                        <h4 class="mb-6 sm:text-xl font-semibold">
                            Please complete the video "<span class="italic">{{ video.prerequisite.name }}</span>" and pass the test first before viewing this video
                        </h4>
                        <a href="/courses" class="button text-xs mx-auto">
                            Back to courses
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <!-- cannot view need to pass a test on the -->
        <h2 class="font-title tracking-tight leading-tight font-bold py-5">
            {{ video.name }}
        </h2>
        <p class="font-normal" v-html="video.description"></p>
        <div v-if="questions.length" id="dialog" class="relative z-10 hidden" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
            <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-full max-w-lg">
                        <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                            <div v-if="quiz === 'choices'" class="sm:flex sm:items-start flex-col">
                                <form class="w-full" @submit.prevent="handleSubmit()">
                                    <div
                                        v-for="(question, index) in questions"
                                        :key="question.id"
                                        class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left"
                                    >
                                        <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">
                                            {{question.title}}
                                        </h3>
                                        <div
                                            v-for="(choice, i) in question.choices"
                                            :key="i"
                                            class="flex items-center mr-4 mb-4"
                                        >
                                            <label :for="`quiz-choice-${[choice.id]}`" class="flex items-center cursor-pointer">
                                                <input
                                                    v-model="choices[index]"
                                                    :id="`quiz-choice-${[choice.id]}`"
                                                    :value="choice.id"
                                                    :name="`quiz-${[question.id]}`"
                                                    type="radio"
                                                    class="mr-2"
                                                />
                                                {{ choice.title }}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="flex flex-row-reverse">
                                        <button
                                            :disabled="this.choices.some(item => item === null)"
                                            type="submit"
                                            :class="['button', {'disabled:opacity-75': this.choices.some(item => item === null)}]"
                                        >
                                            Submit
                                        </button>
                                        <button class="mr-6" @click="hideDialog">Cancel</button>
                                    </div>
                                </form>
                            </div>
                            <div v-else-if="quiz === 'pass'" class="sm:flex sm:items-start">
                                <div class="mx-auto flex h-20 w-20 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                                    <svg class="w-10 h-10 text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 21 21">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m6.072 10.072 2 2 6-4m3.586 4.314.9-.9a2 2 0 0 0 0-2.828l-.9-.9a2 2 0 0 1-.586-1.414V5.072a2 2 0 0 0-2-2H13.8a2 2 0 0 1-1.414-.586l-.9-.9a2 2 0 0 0-2.828 0l-.9.9a2 2 0 0 1-1.414.586H5.072a2 2 0 0 0-2 2v1.272a2 2 0 0 1-.586 1.414l-.9.9a2 2 0 0 0 0 2.828l.9.9a2 2 0 0 1 .586 1.414v1.272a2 2 0 0 0 2 2h1.272a2 2 0 0 1 1.414.586l.9.9a2 2 0 0 0 2.828 0l.9-.9a2 2 0 0 1 1.414-.586h1.272a2 2 0 0 0 2-2V13.8a2 2 0 0 1 .586-1.414Z"></path>
                                    </svg>
                                </div>
                                <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                    <h3 class="text-2xl font-semibold leading-6 text-gray-900">Congratulations</h3>
                                    <div class="mt-2">
                                        <p class="text-base text-gray-500">
                                            Congratulations! You've passed the test. You can continue viewing other courses and videos.
                                        </p>
                                    </div>
                                    <a href="/courses" class="button text-xs">
                                        Back to courses
                                    </a>
                                </div>
                            </div>
                            <div v-else-if="quiz === 'fail'" class="sm:flex sm:items-start">
                                <div class="mx-auto flex h-20 w-20 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                    <svg class="w-10 h-10 text-red-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m15 9-6 6m0-6 6 6m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                                    </svg>
                                </div>
                                <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                    <h3 class="text-2xl font-semibold leading-6 text-gray-900">Not pass</h3>
                                    <div class="mt-2">
                                        <p class="text-base text-gray-500">
                                            You've made some mistake(s) on the test. Please review the lesson and try again.
                                        </p>
                                    </div>
                                    <div class="flex justify-between items-center">
                                        <a href="/courses" class="">
                                            Back to courses
                                        </a>
                                        <button class="button" @click="resetChoices">Retake the test</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CourseIndex",
    components: {
    },
    props: {
        video: {
            default: () => {},
            type: Object,
        },
        questions: {
            default: () => [],
            type: Array,
        },
        userId: {
            default: "",
            type: Number,
        },
    },
    mounted() {
        this.choices = Array.from({ length: this.questions.length }, () => null);
    },
    data() {
        return {
            choices: [],
            quiz: 'choices',
            isLoading: false,
        }
    },
    methods: {
        onEnd() {
            if (this.questions.length) {
                this.showDialog();
            }
        },
        fetchBlobURL() {
            fetch(this.video.url)
                .then(res => res.blob())
                .then((blob) => (document.querySelector('source').src = URL.createObjectURL(blob)));
        },
        handleSubmit() {
            const selectedChoices = this.choices.map((id, index) => {
                const data = this.questions[index].choices.find((choice) => choice.id === id);
                return {
                    question_id: data.question_id,
                    choice_id: id,
                }
            })
            
            const isCorrect = this.choices.map((id, index) => {
                const data = this.questions[index].choices.find((choice) => choice.id === id);
                return data.is_correct;
            })
            
            const pass = isCorrect.every((item) => item === true);

            if (pass) {
                this.quiz = 'pass';
                this.isLoading = true;

                const params = {
                    video_id: this.questions[0].video_id,
                    questions: selectedChoices,
                    user_id: this.userId
                }

                fetch('/api/videos/save-progress', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(params),
                })
                .catch(() => {
                    swal('Oops...', 'Something went wrong!', 'error');
                })
                .then(() => {
                    this.isLoading = false;
                });
            } else {
                this.quiz = 'fail';
            }
        },
        resetChoices() {
            this.quiz = 'choices';
            this.choices = Array.from({ length: this.questions.length }, () => null);
        },
        showDialog() {
            const dialog = document.getElementById('dialog');
            dialog.classList.remove('hidden');
            setTimeout(() => {
                dialog.classList.remove('opacity-0');
            }, 20);
        },
        hideDialog() {
            const dialog = document.getElementById('dialog');
            dialog.classList.add('opacity-0');
            setTimeout(() => {
                this.resetChoices();
                dialog.classList.add('hidden');
            }, 300);
        },
    }
}
</script>
