export const REPORT_STATUSES = {
	TOTAL: 'total',
	CONDITION: 'condition',
    EFFICIENCY: 'efficiency',
    CLEANLINESS: 'cleanliness',
    MANNERS_COURTESY: 'manners_courtesy',
    STAFF_APPEARANCE: 'staff_appearance',
    CONVENIENCE_COMFORT: 'convenience_comfort',
    SALES_REVENUE_GENERATION: 'sales_revenue_generation',
    ELEVATED_BRAND_EXPERIENCE: 'elevated_brand_experience',
    TECHNICAL_SKILL_KNOWLEDGE: 'technical_skill_knowledge',
    THOUGHTFULNESS_PERSONALISATION: 'thoughtfulness_personalisation'
};

export const REPORT_TITLE = {
    [REPORT_STATUSES.TOTAL]: 'TOTAL',
    [REPORT_STATUSES.CONDITION]: 'CONDITION',
    [REPORT_STATUSES.EFFICIENCY]: 'EFFICIENCY',
    [REPORT_STATUSES.CLEANLINESS]: 'CLEANLINESS',
    [REPORT_STATUSES.MANNERS_COURTESY]: 'MANNERS & COURTESY',
    [REPORT_STATUSES.STAFF_APPEARANCE]: 'STAFF APPEARANCE',
    [REPORT_STATUSES.CONVENIENCE_COMFORT]: 'CONVENIENCE & COMFORT',
    [REPORT_STATUSES.SALES_REVENUE_GENERATION]: 'SALES & REVENUE GENERATION',
    [REPORT_STATUSES.ELEVATED_BRAND_EXPERIENCE]: 'ELEVATED BRAND EXPERIENCE',
    [REPORT_STATUSES.TECHNICAL_SKILL_KNOWLEDGE]: 'TECHNICAL SKILL & KNOWLEDGE',
    [REPORT_STATUSES.THOUGHTFULNESS_PERSONALISATION]: 'THOUGHTFULNESS & PERSONALISATION'
};

export const MAIN_CATEGORY = {
    RECEPTION_SERVICES: 'reception_services',
    CLASS_EXPERIENCE: 'class_experience',
	PUBLIC_AREAS: 'public_areas',
    EQUIPMENT_STUDIO: 'equipment_studio',
    DIGITAL_TECHNOLOGY: 'digital_technology',
};

export const MAIN_CATEGORY_TITLE = {
    [MAIN_CATEGORY.RECEPTION_SERVICES]: 'Reception Services',
    [MAIN_CATEGORY.CLASS_EXPERIENCE]: 'Class Experience',
    [MAIN_CATEGORY.PUBLIC_AREAS]: 'Public Areas',
    [MAIN_CATEGORY.EQUIPMENT_STUDIO]: 'Equipment & Studio',
    [MAIN_CATEGORY.DIGITAL_TECHNOLOGY]: 'Digital & Tech',
};

export const SUB_CATEGORY = {
    // Public areas
    STAFF: 'staff',
    LOCKERS: 'lockers',
    BATHROOMS: 'bathrooms',
    GENERAL_AREAS: 'general_areas',
    CHANGING_AREAS: 'changing_areas',
    FOOD_AND_DRINK: 'food_and_drink',
    BRAND_EXPERIENCE: 'brand_experience',
    // Class experiences
    CLASS_ENDING: 'class_ending',
    MAIN_SESSION: 'main_session',
    COACH_SERVICE: 'coach_service',
    PRE_CLASS_PREP: 'pre_class_prep',
    CLASS_BEGINNING: 'class_beginning',
    CLASS_EXPERIENCE: 'class_experience',
    // Equipment & Studio
    EQUIPMENT: 'equipment',
    ENVIRONMENT: 'environment',
    // Digital & Tech
    WEBSITE: 'website',
    TECHNOLOGY: 'technology',
    EMAIL_COMMS: 'email_comms',
    ONLINE_BRAND: 'online_brand',
    DIGITAL_RESERVATION: 'digital_reservation',
    // Reception Services
    SALES: 'sales',
    ARRIVAL: 'arrival',
    DEPARTURE: 'departure',
    RECEPTION: 'reception',
    PHONE_ENQUIRY: 'phone_enquiry'
};

export const SUB_CATEGORY_TITLE = {
    [SUB_CATEGORY.STAFF]: 'Staff (NON-A&D / Coach)',
    [SUB_CATEGORY.LOCKERS]: 'Lockers & Towels',
    [SUB_CATEGORY.BATHROOMS]: 'Bathrooms & Wet Areas',
    [SUB_CATEGORY.GENERAL_AREAS]: 'General Areas',
    [SUB_CATEGORY.CHANGING_AREAS]: 'Changing Areas',
    [SUB_CATEGORY.FOOD_AND_DRINK]: 'Food & Beverage',
    [SUB_CATEGORY.BRAND_EXPERIENCE]: 'Brand Experience',
    [SUB_CATEGORY.CLASS_ENDING]: 'Ending & Post-Class',
    [SUB_CATEGORY.MAIN_SESSION]: 'Main Session',
    [SUB_CATEGORY.COACH_SERVICE]: 'General Coach Services',
    [SUB_CATEGORY.PRE_CLASS_PREP]: 'Pre-Class Preparation',
    [SUB_CATEGORY.CLASS_BEGINNING]: 'Class Set-Up & Beginning',
    [SUB_CATEGORY.CLASS_EXPERIENCE]: 'Class Experience',
    [SUB_CATEGORY.EQUIPMENT]: 'Equipment',
    [SUB_CATEGORY.ENVIRONMENT]: 'Environment & Experience',
    [SUB_CATEGORY.WEBSITE]: 'Website',
    [SUB_CATEGORY.TECHNOLOGY]: 'Technology',
    [SUB_CATEGORY.EMAIL_COMMS]: 'Email & Text Comms',
    [SUB_CATEGORY.ONLINE_BRAND]: 'Online Brand Experience',
    [SUB_CATEGORY.DIGITAL_RESERVATION]: 'Digital Reservaion',
    [SUB_CATEGORY.SALES]: 'Sales & Retention',
    [SUB_CATEGORY.ARRIVAL]: 'Arrival',
    [SUB_CATEGORY.DEPARTURE]: 'Departure',
    [SUB_CATEGORY.RECEPTION]: 'General Reception Service',
    [SUB_CATEGORY.PHONE_ENQUIRY]: 'Phone Enquiry Reservation',
};