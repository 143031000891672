<template>
    <div>
        <button class="flex items-center justify-center gap-5" @click="toggler = !toggler">
			<slot></slot>
		</button>

        <FsLightbox
			:toggler="toggler"
			:sources="gallery_items"
		 />
    </div>
</template>

<script>
import FsLightbox from "fslightbox-vue";

export default {
    components: { FsLightbox },
    props: {
        gallery_items: {
            type: Array,
            required: true,
        }
    },
	data() {
		return {
			toggler: false
		}
	}
}

</script>