export default {
  data() {
    return {
      menuVisible: false,
      pageTop: 0,
      lastScroll: 0,
      header: null,
      activePath: window.location.pathname,
    };
  },
  mounted () {
    this.header = document.getElementById('nav-bar');

    const onHomePage = this.activePath == "/" ? true : false;
    
    if (onHomePage) {
      this.wordmark = document.getElementById('wordmark');
      this.header.classList.remove('bg-brown-100');
      this.wordmark.classList.remove('text-font-dark');
      this.deskNavItems = document.getElementById('desk-nav');
      this.deskNavItems.classList.add('text-white');
      this.deskNavItems.classList.remove('text-black');
    }

    window.addEventListener('scroll', () => {
      if (window.scrollY >= 100) {
          let currentScroll = document.documentElement.scrollTop;
          if (onHomePage) {
            this.header.classList.add('bg-brown-100');
            this.wordmark.classList.add('text-font-dark');
            this.deskNavItems.classList.add('text-black');
            this.deskNavItems.classList.remove('text-white');
          }
          if (!this.menuVisible) {
              if (currentScroll - this.lastScroll > 0) {
                this.header.classList.add('scroll-up');
                this.header.classList.remove('scroll-down');
                this.header.style.cssText = ``;
              }
          }
          this.lastScroll = currentScroll;
      } else {
        if (onHomePage) {
          this.header.classList.remove('bg-brown-100');
          this.wordmark.classList.remove('text-font-dark');
          this.deskNavItems.classList.remove('text-black');
          this.deskNavItems.classList.add('text-white');
        }
      }
  });
  },
  methods: {
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
      const mobile_menu = document.getElementById("mobile-nav");
      mobile_menu.classList.toggle("hidden");
  
      if (this.menuVisible) {
          document.body.classList.add('overflow-hidden');
      } else {
          document.body.classList.remove('overflow-hidden');
      }
    },
  },
  render() {
    return this.$scopedSlots.default({
      menuVisible: this.menuVisible,
      toggleMenu: this.toggleMenu,
    });
  },
};
