<template>
  <div>
    <div
      v-if="showNewsletterModal"
      class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
    >
      <div class="relative w-auto mx-4 my-8 mx-auto max-w-8xl z-50">
        <!--content-->
        <div
          class="bg-font-dark rounded-lg shadow-lg relative flex flex-col w-full bg-black outline-none focus:outline-none"
        >
          <!--header-->
          <div class="flex absolute right-0 items-start justify-between p-5 text-white rounded-t-xl">
            <button
              class="p-1 text-white cursor-pointer ml-auto bg-transparent border-0 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              @click="closeModal()"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <!--body-->
          <div
            class="flex-grow-0 flex-shrink-0 bg-font-dark text-font-light font-base py-0 md:py-20 px-5 border-b border-black"
          >
            <div class="w-full max-w-xl lg:max-w-5xl mx-auto lg:flex">
              <div class="w-full p-5 lg:w-1/3">
                <h2
                  class="font-title tracking-tight leading-tight text-title-mob sm:text-title-desk font-bold mb-5 last:mb-0 text-white"
                >
                  Newsletter
                </h2>
              </div>
              <div class="w-full p-5 lg:w-2/3">
                <div class="prose prose-lg font-dark text-font-light prose-strong:text-font-light mt-2 mb-5">
                  <slot>
                    <p>
                      Sign up to our newsletter for all the latest news and information from The
                      FitGuide.
                    </p>
                  </slot>
                </div>
                <div id="mc_embed_signup">
                  <form
                      action="https://thefitguide.us9.list-manage.com/subscribe/post?u=aba513147bb898e7c35bd9e75&amp;id=9c8afc1c49&amp;f_id=002e28e1f0"
                      method="post"
                      id="mc-embedded-subscribe-form"
                      name="mc-embedded-subscribe-form"
                      class="validate"
                      target="_self"
                    >
                      <div id="mc_embed_signup_scroll">
                          <div class="mc-field-group">
                              <label
                                for="mce-EMAIL"
                                class="block text-[8px] leading-[1.75] sm:text-xs uppercase font-title font-medium text-font-light tracking-[2.5px] mb-[5px] sm:mb-2"
                              >
                                Email
                              </label>
                              <input
                                type="email"
                                value=""
                                name="EMAIL"
                                placeholder="Email Address..."
                                class="w-full font-medium font-base bg-white text-font-dark p-3.75 mb-5 rounded-sm"
                                id="mce-EMAIL"
                                required
                              >
                          </div>
                          <div id="mce-responses" class="clear">
                              <div class="response" id="mce-error-response" style="display:none"></div>
                              <div class="response" id="mce-success-response" style="display:none"></div>
                          </div><!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                          <div style="position: absolute; left: -5000px;" aria-hidden="true">
                              <input type="text" name="b_aba513147bb898e7c35bd9e75_9c8afc1c49" tabindex="-1" value="">
                          </div>
                          <div class="-skew-x-12 transform-gpu bg-orange-500 hover:bg-orange-600 text-center inline-block">
                              <input
                                type="submit"
                                value="Sign up"
                                name="signup"
                                id="mc-embedded-subscribe"
                                class="block skew-x-12 transform-gpu uppercase text-sm text-font-light tracking-extrawide py-3.75 px-7.5 cursor-pointer"
                              >
                          </div>
                      </div>
                    </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showNewsletterModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'newsletterdialog',
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showNewsletterModal: false,
      newsletterShown: false,
      email: '',
      postUrl: '/newsletters',
    };
  },
  created() {
    // Saved in localStorage as a string, so match against a string of 'true'
    this.newsletterShown = localStorage.getItem('newsletterShown') === 'true';
  },
  mounted() {
    this.checkNewsLetterStatus();
  },
  methods: {
    checkNewsLetterStatus() {
      if (!this.newsletterShown) {
        setTimeout(() => {
          this.showNewsletterModal = true;
          this.modalShown();
        }, 10000);
      }
    },
    modalShown() {
      /**
       * Once the Newsletter has been shown prevent it from showing again.
       */
      this.newsletterShown = true;
      localStorage.setItem('newsletterShown', this.newsletterShown);
    },
    closeModal() {
      this.showNewsletterModal = false;
    },

    // sendNewsLetter() {
    //   fetch('/newsletters', {
    //     method: 'POST',
    //     mode: 'no-cors',
    //     headers: {
    //       'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify({
    //       '_token': this.token,
    //       email: this.email,
    //       type: 'signup',
    //     }),
    //   })
    //   .then(response => response.json())
    //   .then((data) => {
    //     console.log(data);
    //     this.closeModal();
    //   }).catch((error) => {
    //     console.error('Error:', error);
    //   })
    // },
  },
};
</script>
