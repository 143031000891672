<template>
    <div v-if="activeReport.content?.scores2?.general" class="w-full max-w-[1340px] m-auto mb:px-4 lg:px-4">
        <div class="flex flex-col bg-brown-100 rounded-lg overflow-hidden my-5">
            <div class="flex flex-col justify-between h-full md:flex-row">
                <chart
                    class="w-full flex-1 py-4 md:py-10 px-4"
                    :chart-type="'bar'"
                    :labels='chartLabel'
                    :values='chartData'
                />
                <div class="flex flex-col w-full md:max-w-[360px] text-center py-4 md:py-10 px-4">
                    <h3 class="uppercase mb-5">Classifications Score %</h3>
                    <ol class="flex flex-col justify-between h-full text-left">
                        <template
                            v-for="(report, keys, index) in activeReport.content?.scores2?.general"
                        >
                            <li
                                v-if="report.percent"
                                :key="report.id"
                            >
                                <div class="flex justify-between items-center">
                                    <p class="text-black/50 uppercase text-xs">
                                        <span class="text-black font-weight-300 text-base">{{ index + 1 }}.</span>
                                        {{ REPORT_TITLE[keys] }}
                                    </p>
                                    <span class="text-lg font-title font-bold">{{ report.percent.toFixed(2) }}</span>
                                </div>
                            </li>
                        </template>
                    </ol>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Chart from "../charts/Chart";
import { REPORT_TITLE } from '../../../enums/reports';

export default {
    components: {
        Chart
    },
    props: {
        location: {
            type: Object,
            required: false,
        },
        activeReport: {
            type: Object
        },
        chartData: {
            type: Array
        },
    },
    data() {
        return {
            REPORT_TITLE
        }
    },
    computed: {
        chartLabel() {
            return Object.entries(this.activeReport?.content?.scores2?.general || {}).map((_,index) => index+1).slice(0, -1);
        }
    },
}
</script>
