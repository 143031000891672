<template>
    <div v-if="location && reports" class="w-full max-w-[1340px] m-auto mb:px-4 lg:px-4">
        <div v-if="location" class="flex flex-col bg-brown-100 rounded-lg overflow-hidden my-5">
            <div class="flex flex-col md:flex-row lg:flex-row">
                <div class="border-r border-black/10 md:max-w-[420px] w-full">
                    <div class="relative overflow-hidden">
                            <a
                                :href="location.city.url"
                                class="absolute top-0 left-0 text-white font-title text-[28px] font-bold py-1 px-5 m-5 bg-black rounded-lg"
                            >
                                {{ location.city.shorthand }}
                            </a>
                            <img class="w-full aspect-[4/3]" v-if="location.thumbnail" :src="location.thumbnail" :alt="location.name">
                            <img v-else class="w-full aspect-[4/3]" src="img/logo-tile.png" alt="">
                            <div
                                class="
                                    absolute
                                    bottom-0
                                    right-0
                                    flex
                                    justify-center
                                    items-center
                                    h-[46px]
                                    bg-[#e3dddd]
                                    px-5
                                    py-1
                                    rounded-tl-lg
                                    font-title
                                "
                            >
                                {{  location.category.name }}
                            </div>
                    </div>
                    <div class="border-b border-black/10 font-title text-[22px] md:text-[28px] font-bold p-5">{{ location.name }}</div>
                    <div class="font-title uppercase p-5">{{ location.city.name }}, {{ location.city.country }}</div>
                </div>
                <div class="w-full p-5">
                    <div class="flex flex-col md:flex-row md:justify-between items-center md:items-start border-b border-black/10 p-4 md:p-2">
                        <div class="flex flex-col items-center md:items-start w-auto mb-6 md:mb-0">
                            <p class="label mb-6 whitespace-nowrap">{{ evaluationTitle }}</p>
                            <component class="star-rating" :is="starRating" />
                        </div>
                        <div>
                            <p class="label mb-2 whitespace-nowrap">{{ totalScoreTitle }}</p>
                            <chart
                                :chart-type="'radial-progress'"
                                :values="totalScore"
                            />
                        </div>
                    </div>

                    <div class="flex flex-col items-center justify-center gap-10">
                        <div class="flex items-center justify-center relative mt-[20px] w-full text-center">
                            <span class="label">{{ categoryTitle }}</span>
                            <button
                                v-if="!isShow"
                                type="button"
                                class="absolute left-0 text-white font-medium p-2.5 text-center inline-flex items-center"
                                @click="backToMainCategory"
                            >
                                <svg class="w-6 h-6 text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5H1m0 0 4 4M1 5l4-4"/>
                                </svg>
                            
                            </button>
                        </div>

                        <transition name="fade" mode="out-in">
                            <div v-if="isShow" class="grid grid-cols-2 md:grid-cols-5 gap-5">
                                <button
                                    v-for="item in filteredMainCategory"
                                    :key="item"
                                    :disabled="reports.content[item].general.total === null"
                                    @click="showSubCategory(item)"
                                >
                                    <chart
                                        :chart-type="'radial-progress'"
                                        :title="MAIN_CATEGORY_TITLE[item]"
                                        :values="reports.content[item].general.total"
                                    />
                                </button>
                            </div>
                            <div v-if="!isShow" class="flex flex-wrap items-center justify-center">
                                <chart
                                    v-for="item in activeSubCategory" :key="item"
                                    :chart-type="'radial-progress'"
                                    :title="SUB_CATEGORY_TITLE[item]"
                                    :values="reports.content[activeCategory][item].total"
                                    class="sub-cat-item"
                                />
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
            <div class="flex flex-col md:flex-row w-full border-t border-black/10">
                <a
                    v-if="isRatingReport && isClubProfileViewable"
                    class="text-center p-5 w-full bg-black text-white"
                    :href="location.url"
                >
                    View Public Club Profile</a>
                <a class="text-center p-5 w-full bg-orange-500 opacity-75 text-white" href="/services">Improve Score</a>
                <template v-if="activeReport.released_at">
                    <a
                        class="text-center p-5 w-full bg-orange-500 text-white"
                        :href="`/enterprise/reports/${activeReport.sheets_id}`"
                    >
                        View Report
                    </a>
                </template>
                <template v-else-if="activeReport.paid_at">
                    <span class="relative relative text-center p-5 w-full bg-blue-500 text-white cursor-help" title="A member of the FitGuide team will review your payment and release this report to you shortly">
                        Paid and awaiting release
                    </span>
                </template>
                <template v-else>
                    <a class="relative text-center p-5 w-full bg-blue-500 text-white"
                        :href="`/purchase/report/${activeReport.sheets_id}`"
                    >
                        Unlock Full Report
                        <img class="absolute right-[10px] top-[20px]" src="/svg/lock.svg" />
                    </a>
                </template>
            </div>
        </div>
        <div v-else class="mt-10 flex flex-col gap-10 justify-center items-center">
            <p>You currently have no reports</p>
            <a class="button" href="/contact-us">
                <span>Request</span>
            </a>
        </div>
    </div>
</template>

<script>
import Chart from "../charts/Chart"
import IconFiveStars from "../icons/IconFiveStars"
import IconFourStars from "../icons/IconFourStars"
import IconThreeStars from "../icons/IconThreeStars"
import IconTwoStars from "../icons/IconTwoStars"
import IconOneStar from "../icons/IconOneStar"
import IconRecommended from "../icons/IconRecommended"
import IconRated from "../icons/IconRated"
import {
    MAIN_CATEGORY,
    SUB_CATEGORY,
    MAIN_CATEGORY_TITLE,
    SUB_CATEGORY_TITLE
} from '../../../enums/reports';

export default {
    components: {
        Chart,
        IconFiveStars,
        IconFourStars,
        IconThreeStars,
        IconTwoStars,
        IconOneStar,
        IconRecommended,
        IconRated,
    },
    props: {
        location: {
            type: Object,
            required: false,
        },
        reports: {
            type: Object,
            required: false,
        },
        activeReport: {
            type: Object
        },
        isRatingReport: {
            type: Boolean
        },
        isClubProfileViewable: {
            type: Boolean
        },
        resetCategory: {
            type: Boolean
        },
    },
    data() {
        return {
            MAIN_CATEGORY_TITLE,
            SUB_CATEGORY_TITLE,
            mainCategory: Object.values(MAIN_CATEGORY),
            SubCategory: Object.values(SUB_CATEGORY),
            isShow: true,
            activeSubCategory: null,
            activeCategory: null,
            keyCategory: null
        }
    },
    computed: {
        starRating() {
            const total = this.activeCategory === null ? this.reports.content.scores1.general.total.overall_rating : this.reports?.content[this.keyCategory].general.overall_rating
            switch (total) {
                case 'five':
                    return IconFiveStars;
                case 'four':
                    return IconFourStars;
                case 'recommended':
                    return IconRecommended;
                default:
                    return IconRated;
            }
        },
        evaluationTitle() {
            return this.activeCategory === null ? 'Overall Rating' : MAIN_CATEGORY_TITLE[this.keyCategory];
        },
        categoryTitle() {
            return this.activeCategory === null ? 'Category Scores' : 'Sub Category Scores';
        },
        totalScore() {
            return this.activeCategory === null ? this.reports.content.scores1.general.total.total : this.reports?.content[this.keyCategory].general.total;
        },
        totalScoreTitle() {
            return this.activeCategory === null ? 'Overall Score' : MAIN_CATEGORY_TITLE[this.keyCategory];
        },
        filteredMainCategory() {
            return Object.keys(this.reports.content).filter((item) => this.mainCategory.includes(item));
        },
	},
    watch: {
        activeReport(newVal, oldVal) {
            if (oldVal?.id && oldVal?.id !== newVal?.id) {
                this.reset();
            };
        },
    },
    methods: {
        showSubCategory(value) {
            this.isShow = !this.isShow;
            this.activeCategory = value;
            this.keyCategory = value;
            this.activeSubCategory = Object.keys(this.reports?.content[value]).filter((item) => this.SubCategory.includes(item));
        },
        backToMainCategory() {
            this.isShow = true;
            this.activeCategory = null;
        },
        reset() {
            this.isShow = true;
            this.activeSubCategory = null;
            this.activeCategory =null;
        }
    }
}
</script>

<style scoped>
.star-rating {
    align-self: center;
    width: 180px;
}

.sub-cat-item {
    align-self: start;
    margin: 8px 0;
    min-width: 160px;
    width: calc(100% / 5 - 16px);
}
</style>
